import React from "react";
import { Stepper, StepLabel, Step, Typography, LinearProgress, Grid, Stack, Avatar } from "@mui/material"
import { linearProgressClasses } from "@mui/material/LinearProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import { stepLineColor, ndidTermsStepLabel, ncbTermsStepLabel, authenStepLabel, resultStepLabel } from "../../config";

const StepperComponent = ({
  steps,
  activeStep
}: {
  steps: string[];
  activeStep: number
}) => {
  const isXs = useMediaQuery('(max-width:600px)');

  function getStepName(stepId: string) {
    switch (stepId) {
      case "ndidTerms":
        return ndidTermsStepLabel;
      case "ncbTerms":
        return ncbTermsStepLabel;
      case "authen":
        return authenStepLabel;
      case "result":
        return resultStepLabel;
    }
  }

  return isXs
    ? (
      <Stack spacing={1} sx={{ mx: 1.5, mt: 2 }}>
        <Stack spacing={1} direction="row" sx={{ justifyContent: "center", alignItems: "center" }}>
          <Avatar
            sx={{
              backgroundColor: stepLineColor,
              width: 25,
              height: 25
            }}
          >
            <Typography>{activeStep + 1}</Typography>
          </Avatar>
          <Typography>{getStepName(steps[activeStep])}</Typography>
        </Stack>
        <Grid spacing={0.5} container>
          {steps.map((step, index) => (
            <Grid xs item>
              <LinearProgress
                variant="determinate"
                value={activeStep >= index ? 100 : 0}
                sx={{
                  height: 5,
                  borderRadius: 5,
                  [`&.${linearProgressClasses.colorPrimary}`]: {
                    backgroundColor: (theme) => theme.palette.grey[300]
                  }
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Stack>
    )
    : (
      <Stepper activeStep={activeStep} alternativeLabel sx={{ mt: 2 }}>
        {steps.map((step, index) => (
          <Step
            key={index}
            sx={{
              "& .MuiStepLabel-root .Mui-completed": {
                color: stepLineColor,
              },
              "& .MuiStepLabel-root .Mui-active": {
                color: stepLineColor,
              },
              "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
              {
                color: "#000000",
              },
              "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
              {
                color: "#000000",
              },
              "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line":
              {
                borderColor: stepLineColor,
              },
              "& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line":
              {
                borderColor: stepLineColor,
              }
            }}
          >
            <StepLabel>{getStepName(step)}</StepLabel>
          </Step>
        ))}
      </Stepper>
    )
}

export default StepperComponent;