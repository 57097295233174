import * as React from "react";
import { Typography, Box, Stack } from "@mui/material";

import nodes from "../../nodes";
import Context, { ActionType } from "../../context";
import IdpTable from "./components/IdpTable";
import Button from "../Common/Button";
import ConfirmationDialog from "../Common/ConfirmationDialog";
import TutorialSection from "./components/TutorialSection";
import { getIdpName } from "../../utils";

export default function SelectIdp({
  onClickPrevious,
  onClickNext
}: {
  onClickPrevious: () => void;
  onClickNext: () => void;
}) {
  const [dialog, setDialog] = React.useState<boolean>(false);

  const { state, dispatch } = React.useContext(Context);
  const { enrolledList, onTheFlyList, selectedIdp } = state;

  const filteredIdp = React.useMemo(() => {
    return nodes.filter(node => enrolledList.includes(node.id));
  }, [enrolledList]);

  const filteredOntheFly = React.useMemo(() => {
    return nodes.filter(node => onTheFlyList.includes(node.id));
  }, [onTheFlyList]);

  return (
    <Box>
      <Box sx={{ textAlign: "center" }}>
        <Typography
          fontWeight="bold"
          variant="h6"
        >
          กรุณาเลือกผู้ให้บริการยืนยันตัวตน ที่ท่านมีแอปพลิเคชันพร้อมใช้งาน
        </Typography>
      </Box>
      <Box>
        <IdpTable
          tableHeader="ผู้ให้บริการที่ท่านได้ลงทะเบียน NDID แล้ว สามารถยืนยันตัวตนได้ทันที"
          idpList={filteredIdp}
        />
      </Box>
      <Box>
        <IdpTable
          tableHeader="ผู้ให้บริการที่นำท่านให้ลงทะเบียน NDID ก่อน จากนั้นจึงยืนยันตัวตนได้"
          idpList={filteredOntheFly}
        />
      </Box>
      <Box>
        <TutorialSection />
      </Box>
      <Stack direction="row" spacing={2} sx={{ justifyContent: "center", my: 2 }}>
        <Button
          buttonText="ย้อนกลับ"
          colorType="cancel"
          type="cancel"
          onClick={onClickPrevious}
        />
        <Button
          buttonText="ยืนยัน"
          disabled={!selectedIdp}
          colorType="confirm"
          type="confirm"
          onClick={() => setDialog(true)}
        />
      </Stack>
      <ConfirmationDialog
        dialogState={dialog}
        closeDialog={() => setDialog(false)}
        dialogTitle="ยืนยันการเลือกผู้ยืนยันตัวตน"
        dialogContent={`ยืนยันการเลือก ${getIdpName(selectedIdp)} เป็นผู้ยืนยันตัวตน`}
        dialogConfirmButtonText="ยืนยัน"
        dialogCancelButtonText="ยกเลิก"
        onClickNext={onClickNext}
      />
    </Box>
  );
}
