import NDIDLogo from "./assets/idpLogo/ndid.svg";
import ttb from "./assets/idpLogo/ttb.png";
import kbank from "./assets/idpLogo/kbank.png";
import scb from "./assets/idpLogo/scb.png";
import bbl from "./assets/idpLogo/bbl.png";
import bay from "./assets/idpLogo/bay.png";
import kkp from "./assets/idpLogo/kkp.png";
import cimb from "./assets/idpLogo/cimb.png";
import gsb from "./assets/idpLogo/gsb.png";
import ghb from "./assets/idpLogo/ghb.png";
import ktb from "./assets/idpLogo/ktb.png";
import baac from "./assets/idpLogo/baac.png";
import ais from "./assets/idpLogo/ais.png";

export default [
  {
    id: "mock1",
    name: "Mock 1",
    img: NDIDLogo,
  },
  {
    id: "mock2",
    name: "Mock 2",
    img: NDIDLogo,
  },
  {
    id: "ttb",
    name: "ธนาคารทหารไทยธนชาต",
    appName: "ttb touch",
    img: ttb,
    contactInfo: {
      businessHours: "24 ชม.",
      phoneNumbers: ["1428​"],
    },
  },
  {
    id: "kbank",
    name: "ธนาคารกสิกรไทย",
    appName: "K PLUS",
    img: kbank,
    contactInfo: {
      businessHours: "24 ชม.",
      phoneNumbers: ["02-8888888​"],
    },
  },
  {
    id: "scb",
    name: "ธนาคารไทยพาณิชย์",
    appName: "SCB Easy",
    img: scb,
    contactInfo: {
      businessHours: "7.00-22.30",
      phoneNumbers: ["02-7777777​"],
    },
  },
  {
    id: "bbl",
    name: "ธนาคารกรุงเทพ",
    appName: "Bualuang mBanking",
    img: bbl,
    contactInfo: {
      businessHours: "24 ชม.",
      phoneNumbers: ["1333", "​02-6455555​"],
    },
  },
  {
    name: "ธนาคารกรุงศรีอยุธยา",
    id: "bay",
    appName: "KMA-Krungsri Mobile App",
    img: bay,
    contactInfo: {
      businessHours: "24 ชม.",
      phoneNumbers: ["1572​​"],
    },
  },
  {
    name: "ธนาคารเกียรตินาคินภัทร",
    id: "kkp",
    appName: "KKP Mobile",
    img: kkp,
    contactInfo: {
      businessHours: "24 ชม.",
      phoneNumbers: ["02-1655555​"],
    },
  },
  {
    name: "ธนาคารซีไอเอ็มบี ไทย",
    id: "cimb",
    appName: "CIMB Thai Digital Banking",
    img: cimb,
    contactInfo: {
      businessHours: "24 ชม.",
      phoneNumbers: ["02-6267777​​"],
    },
  },
  {
    name: "ธนาคารอาคารสงเคราะห์",
    id: "ghb",
    appName: "GHB ALL GEN",
    img: ghb,
    contactInfo: {
      businessHours: "24 ชม.",
      phoneNumbers: ["02-6459000"],
    },
  },
  {
    name: "ธนาคารออมสิน",
    id: "gsb",
    appName: "MyMO by GSB Mobile Banking",
    img: gsb,
    contactInfo: {
      businessHours: "24 ชม.",
      phoneNumbers: ["1143"],
    },
  },
  {
    name: "ธนาคารเพื่อการเกษตร",
    id: "baac",
    appName: "A-Mobile Plus",
    img: baac,
    contactInfo: {
      businessHours: "24 ชม.",
      phoneNumbers: ["02-5550555"],
    },
  },
  {
    name: "ธนาคารกรุงไทย",
    id: "ktb",
    appName: "Krungthai NEXT",
    img: ktb,
    contactInfo: {
      businessHours: "24 ชม.",
      phoneNumbers: ["02-1111111​"],
    },
  },
  {
    name: "แอดวานซ์ อินโฟร์ เซอร์วิส (เอไอเอส)",
    id: "ais",
    appName: "myAIS",
    img: ais,
    contactInfo: {
      businessHours: "24 ชม.",
      phoneNumbers: ["1175"],
    }
  },
];
