import { Card, CardContent, CardMedia, Box } from '@mui/material';

const cardSx = {
    "&.MuiCard-root": {
        border: "none",
        boxShadow: "none",
    }
}

export default function Tutotial({
    image,
    text,
    alt
}: {
    image: string;
    text: string;
    alt: string;
}) {

    return (
        <Box>
            <Card sx={cardSx}>
                <CardMedia
                    component="img"
                    alt={alt}
                    image={image}
                    sx={{
                        maxHeight: {
                            xs: 90,
                            sm: 95,
                            md: 100,
                            lg: 120
                        },
                        mx: "auto",
                        width: "auto"
                    }}
                />
                <CardContent sx={{textAlign:"center", padding: 3 }}>{text}</CardContent>
            </Card>
        </Box>
    )
}