import React from 'react';
import { Typography } from '@mui/material';
import { SessionStorage } from '../../../App';
import Context, { ActionType } from '../../../context';

export default function Timer() {
    const { state, dispatch } = React.useContext(Context);
    const { requestTime, requestTimeOut } = state;

    const [minutes, setMinutes] = React.useState(0);
    const [seconds, setSeconds] = React.useState(0);
    const [deadline, setDeadline] = React.useState(() => {
        const storedRequestTime = sessionStorage.getItem(SessionStorage.REQUEST_TIME);
        if (storedRequestTime) {
            return Number(storedRequestTime) + requestTimeOut * 1000;
        }
        return Date.now() + requestTimeOut * 1000;
    });

    React.useEffect(() => {
        if (requestTime > 0) {
            setDeadline(requestTime + requestTimeOut * 1000);
            sessionStorage.setItem(SessionStorage.REQUEST_TIME, requestTime.toString());
        }
    }, [requestTime]);

    const updateTimer = React.useCallback((now: number, deadline: number) => {
        if (deadline - now > 0) {
            const updatingSeconds = Math.floor(((deadline - now) % (1000 * 60)) / 1000);
            const updatingMinutes = Math.floor(((deadline - now) % (1000 * 60 * 60)) / (1000 * 60));
            setSeconds(updatingSeconds);
            setMinutes(updatingMinutes);
        } else {
            setSeconds(0);
            setMinutes(0);
        }
    }, [setSeconds, setMinutes]);

    React.useEffect(() => {
        updateTimer(Date.now(), deadline);
        let interval = setInterval(() => {
            const now = Date.now();
            if (deadline - now > 0) {
                updateTimer(now, deadline);
            }
            else {
                dispatch({
                    type: ActionType.SET_TIMED_OUT,
                    payload: true
                });
                clearInterval(interval);
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        }
    }, [deadline, updateTimer]);

    return (
        <Typography
            sx={{
                m: 3,
            }}
            variant="h3"
        >
            {("0" + minutes).slice(-2)}:{("0" + seconds).slice(-2)}
        </Typography>
    )
}