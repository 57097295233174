interface ServerConfig {
    host: string;
    apiKey: string;
  }
  
  interface PluginParams {
    payload: any,
    config: ServerConfig;
    instance: any,
  }
  
  export default function EFormAnalyticPlugin(serverConfig: ServerConfig) {
    return {
      name: 'EFormAnalyticPlugin',
      config: {
        ...serverConfig
      },

      initialize: ({ config }: PluginParams) => {
        
      },

      page: ({ payload, config, instance }: PluginParams) => {
        fetch(config.host, {
          method: 'POST',
          headers: {
            'x-api-key': config.apiKey,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ ...payload, ...instance.user() })
        });
      },

      track: ({ payload, config, instance }: PluginParams) => {
        if (!config.host || !config.apiKey) return;

        fetch(config.host, {
          method: 'POST',
          headers: {
            'x-api-key': config.apiKey,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ ...payload, ...instance.user() })
        });
      },

      identify: ({ payload }: PluginParams) => {

      },

      loaded: () => {
        return true;
      }
    }
  }