import React from 'react';

interface INcbRequestParams {
    uuid: string;
    member_shortname: string;
    citizen_id: string;
    firstname: string;
    lastname: string;
    dob: string;
    mobile_no?: string;
    email?: string;
    product_code: string;
    product_code_description: string;
    trn_type: string;
    version: number;
}

interface IComponent {
    element: string;
    props?: any;
    children: string | IComponent[];
}

interface IAction {
    type: ActionType;
    payload: any;
}

interface IState {
    citizenId: string;
    isCitizenIdPrefilled: boolean;
    requestOption: string;
    selectedIdp: string;
    preselectedIdp: string;
    enrolledList: string[];
    onTheFlyList: string[];
    userId: string;
    transactionRef: string;
    requestId: string;
    ncbRequestParams?: INcbRequestParams;
    timedOut: boolean;
    requestStatus: string;
    errorCode: number | null;
    requestTime: number;
    isLoading: boolean;
    ndidTerms?: IComponent;
    ncbTerms?: IComponent;
    requestTimeOut: number;
}

const initialState: IState = {
    citizenId: "",
    isCitizenIdPrefilled: false,
    requestOption: "default",
    selectedIdp: "",
    preselectedIdp: "",
    enrolledList: [],
    onTheFlyList: [],
    userId: "",
    transactionRef: "",
    requestId: "",
    ncbRequestParams: undefined,
    timedOut: false,
    requestStatus: "",
    errorCode: null,
    requestTime: 0,
    isLoading: false,
    ndidTerms: undefined,
    ncbTerms: undefined,
    requestTimeOut: 3600
}

export enum ActionType {
    SET_CITIZEN_ID = "SET_CITIZEN_ID",
    SET_CITIZEN_ID_PREFILLED = "SET_CITIZEN_ID_PREFILLED",
    SET_REQUEST_OPTION = "SET_REQUEST_OPTION",
    SET_SELECTED_IDP = "SET_SELECTED_IDP",
    SET_PRESELECTED_IDP = "SET_PRESELECTED_IDP",
    SET_ENROLLED_LIST = "SET_ENROLLED_LIST",
    SET_ON_THE_FLY_LIST = "SET_ON_THE_FLY_LIST",
    SET_USER_ID = "SET_UID",
    SET_TRANSACTION_REF = "SET_TRANSACTION_REF",
    SET_REQUEST_ID = "SET_REQUEST_ID",
    SET_NCB_REQUEST_PARAMS = "SET_NCB_REQUEST_PARAMS",
    SET_TIMED_OUT = "SET_TIMED_OUT",
    SET_REQUEST_STATUS = "SET_REQUEST_STATUS",
    SET_ERROR_CODE = "SET_ERROR_CODE",
    SET_REQUEST_TIME = "SET_REQUEST_TIME",
    SET_LOADING = "SET_LOADING",
    SET_NDID_TERMS = "SET_NDID_TERMS",
    SET_NCB_TERMS = "SET_NCB_TERMS",
    SET_REQUEST_TIMEOUT = "SET_REQUEST_TIMEOUT"
}

const reducer = (state: IState, action: IAction) => {
    switch (action.type) {
        case ActionType.SET_CITIZEN_ID:
            return {
                ...state,
                citizenId: action.payload
            }
        case ActionType.SET_CITIZEN_ID_PREFILLED:
            return {
                ...state,
                isCitizenIdPrefilled: action.payload
            }
        case ActionType.SET_REQUEST_OPTION:
            return {
                ...state,
                requestOption: action.payload
            }
        case ActionType.SET_SELECTED_IDP:
            return {
                ...state,
                selectedIdp: action.payload
            }
        case ActionType.SET_PRESELECTED_IDP:
            return {
                ...state,
                preselectedIdp: action.payload
            }
        case ActionType.SET_ENROLLED_LIST:
            return {
                ...state,
                enrolledList: action.payload
            }
        case ActionType.SET_ON_THE_FLY_LIST:
            return {
                ...state,
                onTheFlyList: action.payload
            }
        case ActionType.SET_USER_ID:
            return {
                ...state,
                userId: action.payload
            }
        case ActionType.SET_TRANSACTION_REF:
            return {
                ...state,
                transactionRef: action.payload
            }
        case ActionType.SET_REQUEST_ID:
            return {
                ...state,
                requestId: action.payload
            }
        case ActionType.SET_NCB_REQUEST_PARAMS:
            return {
                ...state,
                ncbRequestParams: action.payload
            }
        case ActionType.SET_TIMED_OUT:
            return {
                ...state,
                timedOut: action.payload
            }
        case ActionType.SET_REQUEST_STATUS:
            return {
                ...state,
                requestStatus: action.payload
            }
        case ActionType.SET_ERROR_CODE:
            return {
                ...state,
                errorCode: action.payload
            }
        case ActionType.SET_REQUEST_TIME:
            return {
                ...state,
                requestTime: action.payload
            }
        case ActionType.SET_LOADING:
            return {
                ...state,
                isLoading: action.payload
            }
        case ActionType.SET_NDID_TERMS:
            return {
                ...state,
                ndidTerms: action.payload
            }
        case ActionType.SET_NCB_TERMS:
            return {
                ...state,
                ncbTerms: action.payload
            }
        case ActionType.SET_REQUEST_TIMEOUT:
            return {
                ...state,
                requestTimeOut: action.payload
            }
        default:
            return state;
    }
}

const Context = React.createContext<{ state: IState, dispatch: React.Dispatch<any> }>({
    state: initialState,
    dispatch: () => null
});

export const ContextProvider: React.FC = ({ children }) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    return (
        <Context.Provider value={{ state, dispatch }} >
            {children}
        </Context.Provider>
    )
}

export default Context;