import React from "react";

import { Typography, Box, Stack } from "@mui/material";
import Button from "../Common/Button";
import Timer from "./components/Timer";
import Context, { ActionType } from "../../context";
import ConfirmationDialog from "../Common/ConfirmationDialog";
import { getIdpName } from "../../utils";
import { marketingNameTh } from "../../config";

export default function Pending({
  onCancel
}: {
  onCancel: () => void;
}) {
  const [dialog, setDialog] = React.useState<boolean>(false);

  const { state, dispatch } = React.useContext(Context);
  const { selectedIdp, preselectedIdp, transactionRef, requestOption } = state;

  return (
    <Box
      sx={{
        textAlign: "center",
        padding: 4,
      }}
    >
      <Box
        display={{
          xs: "block",
          sm: "inline-flex"
        }}
      >
        <Typography
          fontSize={{
            xs: 16,
            sm: 18
          }}
          px={0.5}
        >
          ท่านกำลังยืนยันตัวตนเพื่อใช้ตามวัตถุประสงค์ของ
        </Typography>
        <Typography
          fontSize={{
            xs: 16,
            sm: 18
          }}
          variant="h6"
          fontStyle="italic"
        >
          {marketingNameTh}
        </Typography>
      </Box>
      <br />
      <Box
        display={{
          xs: "block",
          sm: "inline-flex"
        }}
      >
        <Typography
          fontSize={{
            xs: 16,
            sm: 18
          }}
          px={0.5}
        >
          และประสงค์ให้ส่งข้อมูลจาก
        </Typography>
        <Typography
          fontSize={{
            xs: 16,
            sm: 18
          }}
          variant="h6"
          fontStyle="italic"
        >
          {getIdpName(
            selectedIdp
              ? selectedIdp
              : preselectedIdp
          )}
          {requestOption === "2" || requestOption === "3" ? " และ บริษัทข้อมูลเครดิตแห่งชาติ" : ""}
        </Typography>
      </Box>
      <br />
      <Typography
        fontSize={{
          xs: 16,
          sm: 18
        }}
      >
        (หมายเลขอ้างอิง: {transactionRef})
      </Typography>
      <Typography
        sx={{
          mt: 3
        }}
        fontSize={{
          xs: 15,
          sm: 18
        }}
      >
        กรุณาไปยืนยันตัวตนที่โมบายแอปพลิเคชันของผู้ให้บริการที่ท่านเลือก
        ภายใน 60 นาที และกลับมาทำรายการต่อที่นี่
      </Typography>
      <Box sx={{ my: 5 }}>
        <Timer />
      </Box>
      <Box sx={{ marginTop: 5 }}>
        <Button
          buttonText="ยกเลิก"
          colorType="cancel"
          onClick={() => setDialog(true)}
        />
      </Box>
      <ConfirmationDialog
        dialogState={dialog}
        closeDialog={() => setDialog(false)}
        dialogTitle="ยืนยันการยกเลิกรายการ"
        dialogContent={`ท่านต้องการที่จะยกเลิกรายการ`}
        dialogConfirmButtonText="ยืนยัน"
        dialogCancelButtonText="ย้อนกลับ"
        onClickNext={onCancel}
      />
    </Box>
  );
  // ท่านได้ยกเลิกรายการคำขอหรือเปลี่ยนผู้ให้บริการยืนยันตัวตน กรุณาเลือก IdP รายอื่น
}
