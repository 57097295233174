import { Box, Typography, Grid, TextField, Stack } from "@mui/material";
import React from "react";
import NcbTermsDeclined from "./components/NcbTermsDeclined";
import TermsBox from "../Common/TermsBox";
import Button from "../Common/Button";
import ConfirmationDialog from "../Common/ConfirmationDialog";
import Context, { ActionType } from "../../context";

export default function NcbTerms({
    onClickNext,
    sendTermsAcceptanceLog
}: {
    onClickNext: () => void;
    sendTermsAcceptanceLog: (termsType: string, value: string) => void;
}) {

    const [dialog, setDialog] = React.useState<boolean>(false);
    const [isNcbTermsFullyScrolled, setNcbTermsFullyScrolled] = React.useState<boolean>(false)
    const [isNcbTermsDeclined, setNcbTermsDeclined] = React.useState<boolean>(false);

    const { state, dispatch } = React.useContext(Context);
    const { ncbRequestParams, ncbTerms } = state;
    
    const {
        citizen_id,
        firstname,
        lastname,
        dob,
        mobile_no,
        product_code,
        product_code_description
    } = ncbRequestParams!;

    const getDobString = (dob: string) => {
        const getMonthName = (month: string) => {
            switch (month) {
                case "01":
                    return "มกราคม"
                case "02":
                    return "กุมภาพันธ์"
                case "03":
                    return "มีนาคม"
                case "04":
                    return "เมษายน"
                case "05":
                    return "พฤษภาคม"
                case "06":
                    return "มิถุนายน"
                case "07":
                    return "กรกฎาคม"
                case "08":
                    return "สิงหาคม"
                case "09":
                    return "กันยายน"
                case "10":
                    return "ตุลาคม"
                case "11":
                    return "พฤศจิกายน"
                case "12":
                    return "ธันวาคม"
            }
        }
        return `${dob.substring(6, 8)} ${getMonthName(dob.substring(4, 6))} ${Number(dob.substring(0, 4)) + 543}`;
    }

    return isNcbTermsDeclined
        ? (<NcbTermsDeclined />)
        : (
            <Grid container spacing={2}>
                <Grid item>
                    <Box sx={{ textAlign: "center" }}>
                        <Typography sx={{ fontWeight: "bold", justifyContent: "center" }} variant="h6">
                            ความยินยอมในการเปิดเผยข้อมูลโดยวิธีการผ่านระบบอินเทอร์เน็ต
                        </Typography>
                    </Box>
                    <Stack direction="column" spacing={1}>
                        {
                            (firstname && lastname) &&
                            <TextField
                                label="ชื่อ"
                                size="small"
                                variant="standard"
                                value={`${firstname} ${lastname}`}
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        }
                        {
                            dob &&
                            <TextField
                                label="วัน / เดือน / ปี พ.ศ. เกิด"
                                size="small"
                                variant="standard"
                                value={getDobString(dob)}
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        }
                        {
                            citizen_id &&
                            <TextField
                                label="หมายเลขบัตรประชาชน"
                                size="small"
                                variant="standard"
                                value={citizen_id}
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        }
                        {
                            mobile_no &&
                            <TextField
                                label="หมายเลขโทรศัพท์์"
                                size="small"
                                variant="standard"
                                value={mobile_no}
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        }
                        {
                            product_code &&
                            <TextField
                                label="ประเภทสินเชื่อ"
                                size="small"
                                variant="standard"
                                value={`${product_code}-${product_code_description}`}
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        }
                    </Stack>
                    <TermsBox
                        id="ncb-terms-box"
                        termsJson={ncbTerms!}
                        onFullyScrolled={() => setNcbTermsFullyScrolled(true)}
                    />
                    <Stack direction="row" spacing={2} sx={{ justifyContent: "center", my: 2 }}>
                        <Button
                            disabled={!isNcbTermsFullyScrolled}
                            colorType="cancel"
                            buttonText="ไม่ยินยอม"
                            onClick={() => setDialog(true)}
                        />
                        <Button
                            disabled={!isNcbTermsFullyScrolled}
                            colorType="confirm"
                            buttonText="ยินยอม"
                            onClick={() => {
                                sendTermsAcceptanceLog("ncbTerms", "accepted");
                                onClickNext();
                            }}
                        />
                    </Stack>
                    <ConfirmationDialog
                        dialogState={dialog}
                        closeDialog={() => setDialog(false)}
                        dialogTitle="ไม่ยินยอมให้เปิดเผยข้อมูลเครดิต"
                        dialogContent="ท่านไม่ต้องการให้เปิดเผยข้อมูลเครดิตเพื่อประกอบการพิจารณา"
                        dialogConfirmButtonText="ยืนยัน"
                        dialogCancelButtonText="ยกเลิก"
                        onClickNext={() => {
                            sendTermsAcceptanceLog("ncbTerms", "declined");
                            setNcbTermsDeclined(true);
                        }}
                    />
                </Grid>
            </Grid>
        )
}