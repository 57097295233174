import React from 'react';
import { Card, CardActions, CardMedia, FormControlLabel, Radio, Box } from '@mui/material';

import Context, { ActionType } from '../../../context';

const cardSx = {
    "&.MuiCard-root": {
        border: "none",
        boxShadow: "none"
    }
}

export default function IdpCard({
    logo,
    id,
    name
}: {
    logo: string;
    id: string;
    name: string;
}) {

    const { state, dispatch } = React.useContext(Context);
    const { selectedIdp } = state;

    const handleChange = (checked: boolean) => {
        if (checked) {
            dispatch({
                type: ActionType.SET_SELECTED_IDP,
                payload: id
            });
        }
    }

    return (
        <Box>
            <Card sx={cardSx}>
                <CardMedia
                    component="img"
                    alt={name}
                    image={logo}
                    sx={{
                        borderRadius: "20px",
                        maxWidth: {
                            xs: 75,
                            sm: 80,
                            md: 85,
                            lg: 100
                        },
                        mx: "auto"
                    }}
                />
                <CardActions>
                    <FormControlLabel
                        control={<Radio />}
                        label={name}
                        value={id}
                        checked={selectedIdp === id}
                        onChange={(e: any) => handleChange(e.target.checked)}
                        sx={{
                            mx: "auto"
                        }}
                    />
                </CardActions>
            </Card>
        </Box>
    )
}