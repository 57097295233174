import * as React from "react";
import { Typography, Grid, Box, Stack } from "@mui/material";
// import CitizenIdTextField from './components/CitizenIdTextField';
import TermsBox from "../Common/TermsBox";
import Button from "../Common/Button";
import Context, { ActionType } from "../../context";
import { redirectUrlOnNdidTermsDeclined } from "../../config";

interface IComponent {
  element: string;
  props?: any;
  children: string | IComponent[];
}

export default function NdidTerms({
  onClickNext,
  sendTermsAcceptanceLog
}: {
  onClickNext: (citizenId: string) => void;
  sendTermsAcceptanceLog: (termsType: string, value: string) => void;
}) {
  const [isTermsFullyScrolled, setTermsFullyScrolled] = React.useState<boolean>(false);
  const [isCitizenIdError, setCitizenIdError] = React.useState<boolean>(false);

  const { state, dispatch } = React.useContext(Context);
  const { citizenId, userId, ndidTerms } = state;

  return (
    <Grid container spacing={2}>
      {/* <Grid sx={{ marginLeft: "auto", marginRight: "auto", my: 1 }} item xs={7} sm={5} md={4} lg={3}>
        <CitizenIdTextField isCitizenIdError={isCitizenIdError} setCitizenIdError={setCitizenIdError}/>
      </Grid> */}
      <Grid item>
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Typography sx={{ fontWeight: "bold", mx: 1 }} variant="h6">
              เงื่อนไขข้อบังคับขั้นต่ำการใช้บริการ NDID
            </Typography>
          </Box>
          <TermsBox
            id="ndid-terms"
            termsJson={ndidTerms!}
            onFullyScrolled={() => setTermsFullyScrolled(true)}
          />
          <Stack direction="row" spacing={2} sx={{ justifyContent: "center", my: 2 }}>
            <Button
              disabled={!isTermsFullyScrolled}
              colorType="cancel"
              buttonText="ปฏิเสธ"
              onClick={() => {
                sendTermsAcceptanceLog("ndidTerms", "declined");
                window.location.replace(`${redirectUrlOnNdidTermsDeclined}${userId ? `?uid=${userId}` : ''}`);
              }}
            />
            <Button
              disabled={!(isTermsFullyScrolled && !isCitizenIdError && citizenId.length === 13)}
              colorType="confirm"
              buttonText="ตกลง"
              onClick={() => {
                sendTermsAcceptanceLog("ndidTerms", "accepted");
                onClickNext(citizenId);
              }}
            />
          </Stack>
      </Grid>
    </Grid>
  );
}
