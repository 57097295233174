import React from 'react';
import { Paper } from '@mui/material';

interface IComponent {
    element: string;
    props?: any;
    children: string | IComponent[];
}

export default function TermsBox({
    id,
    termsJson,
    onFullyScrolled
}: {
    id: string;
    termsJson: IComponent,
    onFullyScrolled: () => void;
}) {

    React.useEffect(() => {
        const dom = document.getElementById(id);
        if (dom !== null) {
            if (dom.scrollHeight <= dom.clientHeight) {
                onFullyScrolled();
            }
        }
    }, []);

    const handleScroll = () => {
        const dom = document.getElementById(id);
        if (dom !== null) {
            const scrollPercent = (document.body.scrollTop + dom.scrollTop) / (dom.scrollHeight - dom.clientHeight);
            if (scrollPercent >= 0.95) {
                onFullyScrolled();
            }
        }
    }

    const renderComponent = ({ element, props, children }: IComponent): React.ReactElement => {
        return React.createElement(
            element,
            props,
            children instanceof Array
                ? children.map((childComponent: IComponent, index: number) => renderComponent({
                    element: childComponent.element,
                    props: {
                        ...childComponent.props,
                        key: index
                    },
                    children: childComponent.children
                }))
                : children
        );
    }

    return (
            <Paper
                id={id}
                variant="outlined"
                onScroll={handleScroll}
                sx={{
                    mx: {
                        xs: 0,
                        sm: 0
                    },
                    my: 2,
                    padding: 1,
                    overflowY: "auto",
                    maxHeight: {
                        xs: 300,
                        md: 700,
                    }
                }}
            >
                {renderComponent(termsJson)}
            </Paper>
    )
}