export const apiBaseUrl: string = window._env_.API_BASE_URL || '/api';
export const wsUrl: string = window._env_.WS_URL || `wss://${window.location.host}/socket`;
export const analyticApiUrl: string = window._env_.ANALYTIC_API_URL || '/analytic/api/submit';
export const analyticApiKey: string = window._env_.ANALYTIC_API_KEY || '';
export const redirectUrlOnNdidTermsDeclined: string = window._env_.REDIRECT_URL_ON_NDID_TERMS_DECLINED || '';
export const redirectUrlOnNcbTermsDeclined: string = window._env_.REDIRECT_URL_ON_NCB_TERMS_DECLINED || '';
export const redirectUrlOnCompleted: string = window._env_.REDIRECT_URL_ON_COMPLETED || '';
export const redirectUrlOnCanceled: string = window._env_.REDIRECT_URL_ON_CANCELED || '';
export const redirectUrlOnErrored: string = window._env_.REDIRECT_URL_ON_ERRORED || '';
export const resultPageObjectiveMessage: string = window._env_.RESULT_PAGE_OBJECTIVE_MESSAGE || '';
export const cancelButtonColor: string = window._env_.CANCEL_BUTTON_COLOR || '#1976D2';
export const confirmButtonColor: string = window._env_.CONFIRM_BUTTON_COLOR || '#1976D2';
export const stepLineColor: string = window._env_.STEP_LINE_COLOR || '#1976D2';
export const inputBoxColor: string = window._env_.INPUT_BOX_COLOR || '#1976D2';
export const marketingNameEn: string = window._env_.MARKETING_NAME_EN || '';
export const marketingNameTh: string = window._env_.MARKETING_NAME_TH || '';
export const ndidTermsStepLabel: string = window._env_.NDID_TERMS_STEP_LABEL || 'ยอมรับเงื่อนไขบริการ NDID';
export const ncbTermsStepLabel: string = window._env_.NCB_TERMS_STEP_LABEL || 'ยินยอมเปิดเผยข้อมูลเครดิต';
export const authenStepLabel: string = window._env_.AUTHEN_STEP_LABEL || 'เลือกผู้ให้บริการและยืนยันตัวตน';
export const resultStepLabel: string = window._env_.RESULT_STEP_LABEL || 'สิ้นสุดการทำรายการ';
export const recaptchaKey: string = window._env_.RECAPTCHA_KEY || '';
export const trackingParamKeyName: string = window._env_.TRACKING_PARAM_KEY_NAME || 'uid';