import React from 'react';
import { Box, Typography } from '@mui/material';
import Button from '../../Common/Button';
import Context from "../../../context";
import { redirectUrlOnNcbTermsDeclined } from '../../../config';

export default function NcbTermsDeclined() {
    const { state } = React.useContext(Context);
    const { userId } = state;

    return (
        <React.Fragment>
            <Box
                sx={{
                    textAlign: "center",
                }}
            >
                <Typography>ขอบคุณที่ให้ความสนใจบริการ</Typography>
                <Typography>หากประสงค์ส่งคำขอค้ำประกันอีกครั้ง กรุณากลับที่เมนูหลัก</Typography>
                <Typography>หรือติดต่อ Callcenter XXXXX</Typography>
            </Box>
            <Box
                sx={{
                    justifyContent: "center",
                    my: 2
                }}
            >
                <Button
                    buttonText='กลับหน้าหลัก'
                    colorType='cancel'
                    onClick={() => window.location.replace(`${redirectUrlOnNcbTermsDeclined}${userId ? `?uid=${userId}` : ''}`)}
                />
            </Box>
        </React.Fragment>
    )
}