import React from 'react';
import { Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, Slide, Stack } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import Button from './Button';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmationDialog({
  dialogState,
  closeDialog,
  dialogTitle,
  dialogContent,
  onClickNext,
  dialogConfirmButtonText,
  dialogCancelButtonText
}: {
  dialogState: boolean;
  closeDialog: () => void;
  dialogTitle: string;
  dialogContent: string;
  dialogConfirmButtonText: string;
  dialogCancelButtonText: string;
  onClickNext: () => void;
}) {

  return (
    <Dialog
      open={dialogState}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
    >
      <DialogTitle sx={{ textAlign: "center" }}>{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogContent}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Stack direction="row" spacing={3} sx={{ my: 2 }}>
          <Button
            buttonText={dialogCancelButtonText}
            colorType="cancel"
            onClick={closeDialog}
          />
          <Button
            buttonText={dialogConfirmButtonText}
            colorType="confirm"
            onClick={onClickNext}
          />
        </Stack>
      </DialogActions>
    </Dialog>
  )
}