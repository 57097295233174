import React from "react";
import axios from "axios";
import * as qs from "query-string";
import { CssBaseline, Paper, Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import './App.css';
import Context, { ActionType } from "./context";
import { restoreState, encrypt, decrypt } from "./utils";
import { apiBaseUrl, redirectUrlOnCanceled, redirectUrlOnErrored, wsUrl, recaptchaKey, trackingParamKeyName } from "./config";
import analytics from "./analytics";
import { idpIdList } from "./utils";

import NdidTerms from "./components/NdidTerms";
import NcbTerms from "./components/NcbTerms";
import Pending from "./components/Pending";
import Result from "./components/Result";
import SelectIdp from "./components/SelectIdp";
import Navbar from "./components/Common/Navbar";
import Stepper from "./components/Common/Stepper";
import Loading from "./components/Common/Loading";

declare global {
  interface Window {
    grecaptcha: any
  }
}

interface ICreateRequestResponse {
  reference_id: string;
  request_id: string;
  additional_data: {
    request_timeout: number;
  }
}

interface IGetIdpResponse {
  idp_list: string[];
  on_the_fly_list: string[];
}

export enum SessionStorage {
  REQUEST_STATE = "request_state",
  IDP_LIST = "idp_list",
  STEPS = "steps",
  COMPONENTS = "components",
  ACTIVE_STEP = "active_step",
  ACTIVE_COMPONENT = "active_component",
  TICKET_ID = "ticket_id",
  PRESELECTED_IDP = "preselected_idp",
  UID = "uid",
  REQUEST_OPTION = "request_option",
  REQUEST_STATUS = "request_status",
  IS_CITIZEN_ID_PREFILLED = "is_citizen_id_prefilled",
  REQUEST_TIME = "request_time",
  CID = "cid",
  REQUEST_PARAMS = "request_params",
  INITIALIZED = "initialized"
}

const initialComponents = [
  "ndidTerms",
  "pending",
  "result",
];

const initialSteps = [
  "ndidTerms",
  "authen",
  "result"
];

const searchParams = qs.parse(window.location.search);
const { ticket_id, idp, request_option } = searchParams;

const uid = Object.hasOwn(searchParams, trackingParamKeyName)
  ? searchParams[trackingParamKeyName]
  : undefined

export default function App() {
  const [isInitialized, setInitialized] = React.useState<boolean>(false);

  const [components, setComponents] = React.useState<string[]>(() => {
    const storedComponents = sessionStorage.getItem(SessionStorage.COMPONENTS);
    if (storedComponents) {
      return JSON.parse(storedComponents);
    }
    return initialComponents;
  });

  const [steps, setSteps] = React.useState<string[]>(() => {
    const storedSteps = sessionStorage.getItem(SessionStorage.STEPS);
    if (storedSteps) {
      return JSON.parse(storedSteps);
    }
    return initialSteps;
  });

  const [activeComponent, setActiveComponent] = React.useState<number>(() => {
    const storedActiveComponent = sessionStorage.getItem(SessionStorage.ACTIVE_COMPONENT);
    if (storedActiveComponent) {
      return Number(storedActiveComponent);
    }
    return 0;
  });

  const [activeStep, setActiveStep] = React.useState<number>(() => {
    const storedActiveStep = sessionStorage.getItem(SessionStorage.ACTIVE_STEP);
    if (storedActiveStep) {
      return Number(storedActiveStep);
    }
    return 0;
  });

  const { state, dispatch } = React.useContext(Context);
  const { selectedIdp, preselectedIdp, citizenId, userId, requestId, timedOut, requestStatus, ncbRequestParams, enrolledList, onTheFlyList, requestOption, errorCode } = state;

  React.useEffect(() => {
    (async () => {
      const [ndidTerms, ncbTerms] = await Promise.all([
        axios.get("config/ndid-terms.json", { headers: { 'Cache-Control': 'no-cache' } }),
        axios.get("config/ncb-terms.json", { headers: { 'Cache-Control': 'no-cache' } })
      ]);

      dispatch({ type: ActionType.SET_NDID_TERMS, payload: ndidTerms.data });
      dispatch({ type: ActionType.SET_NCB_TERMS, payload: ncbTerms.data });

      const initialized = sessionStorage.getItem(SessionStorage.INITIALIZED);

      if (initialized) {
        restoreState(dispatch);
      }

      if (typeof uid === "string") {
        dispatch({ type: ActionType.SET_USER_ID, payload: uid });
        sessionStorage.setItem(SessionStorage.UID, uid);
      }

      if (typeof request_option === "string") {
        dispatch({ type: ActionType.SET_REQUEST_OPTION, payload: request_option });
        sessionStorage.setItem(SessionStorage.REQUEST_OPTION, request_option);
      }

      if (typeof ticket_id === 'string') {
        const storedTicketId = sessionStorage.getItem(SessionStorage.TICKET_ID);

        if (!storedTicketId || storedTicketId !== ticket_id) {
          try {
            const preservedData = await getPreservedData(ticket_id);
            sessionStorage.setItem(SessionStorage.TICKET_ID, ticket_id);

            if (preservedData) {
              const { idp_list, on_the_fly_list }: IGetIdpResponse = await getIdpList(preservedData);

              if (typeof idp === "string" && idp_list.includes(idp)) {
                dispatch({ type: ActionType.SET_PRESELECTED_IDP, payload: idp });
                sessionStorage.setItem(SessionStorage.PRESELECTED_IDP, idp);
              }
              else {
                insertComponent("selectIdp");
                dispatch({ type: ActionType.SET_ENROLLED_LIST, payload: idp_list });
                dispatch({ type: ActionType.SET_ON_THE_FLY_LIST, payload: on_the_fly_list });
              }

              dispatch({ type: ActionType.SET_CITIZEN_ID, payload: preservedData });
              dispatch({ type: ActionType.SET_CITIZEN_ID_PREFILLED, payload: true });

              sessionStorage.setItem(SessionStorage.CID, encrypt(preservedData));
              sessionStorage.setItem(SessionStorage.IS_CITIZEN_ID_PREFILLED, "true");
            }
            else {
              if (redirectUrlOnErrored) {
                window.location.replace(`${redirectUrlOnErrored}${uid ? `?uid=${uid}` : ''}`);
              }
              else {
                handleRequestClosedOrTimedOut();  // Since NCB data does not exist and redirect url is not set, force user to the Result page with default error message.
                setInitialized(true);
                return;
              }
            }
          }
          catch (err) {
            alert("ไม่สามารถทำรายการได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง");
          }
        }
        else {
          const storedCid = sessionStorage.getItem(SessionStorage.CID);
          const storedNcbParams = sessionStorage.getItem(SessionStorage.REQUEST_PARAMS);
          const storedIsCidPrefilled = sessionStorage.getItem(SessionStorage.IS_CITIZEN_ID_PREFILLED);

          if (storedCid) dispatch({ type: ActionType.SET_CITIZEN_ID, payload: decrypt(storedCid) });
          if (storedNcbParams) dispatch({ type: ActionType.SET_NCB_REQUEST_PARAMS, payload: JSON.parse(decrypt(storedNcbParams)) });
          if (storedIsCidPrefilled) dispatch({ type: ActionType.SET_CITIZEN_ID_PREFILLED, payload: storedIsCidPrefilled === "true" ? true : false });
        }
      }
      else {
        if (typeof idp === 'string' && idpIdList.includes(idp)) {
          dispatch({ type: ActionType.SET_PRESELECTED_IDP, payload: idp });
          sessionStorage.setItem(SessionStorage.PRESELECTED_IDP, idp);
        }
        else {
          insertComponent("selectIdp");
        }
      }
      setInitialized(true);
      sessionStorage.setItem(SessionStorage.INITIALIZED, "true");

    })();
  }, []);

  React.useEffect(() => {
    if (!recaptchaKey) return;

    const isScriptExist = document.getElementById("recaptcha-key");

    if (!isScriptExist) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`;
      script.id = "recaptcha-key";
      document.body.appendChild(script);
    }
  }, []);

  React.useEffect(() => sessionStorage.setItem(SessionStorage.ACTIVE_STEP, activeStep.toString()), [activeStep]);

  React.useEffect(() => {
    sessionStorage.setItem(SessionStorage.ACTIVE_COMPONENT, activeComponent.toString());

    let url = '';
    let referer = '';

    try {
      url = window.location.href;
    }
    catch {}
    try {
      referer = document.referrer;
    }
    catch {}

    analytics.track('page', {
      active_step: components[activeComponent],
      url,
      referer,
    });

  }, [activeComponent]);

  React.useEffect(() => sessionStorage.setItem(SessionStorage.STEPS, JSON.stringify(steps)), [steps]);
  React.useEffect(() => sessionStorage.setItem(SessionStorage.COMPONENTS, JSON.stringify(components)), [components]);

  React.useEffect(() => {
    if (enrolledList.length || onTheFlyList.length) {
      sessionStorage.setItem(
        SessionStorage.IDP_LIST,
        JSON.stringify({
          enrolledList,
          onTheFlyList
        })
      );
    }
  }, [enrolledList, onTheFlyList]);

  const generateRecaptchaToken = React.useCallback(() => {
    if (!recaptchaKey) return;

    return new Promise(resolve => {
      window.grecaptcha.ready(async () => {
        const token: string = await window.grecaptcha.execute(
          recaptchaKey,
          { action: 'request' }
        );
        resolve(token);
      });
    })
  }, []);

  function handleNextStep() {
    setActiveStep(prevActiveStep => {
      return activeStep < steps.length - 1
        ? prevActiveStep + 1
        : prevActiveStep
    });
  }

  function handlePreviousStep() {
    setActiveStep(prevActiveStep => {
      return activeStep > 0
        ? prevActiveStep - 1
        : prevActiveStep
    });
  }

  function handleNextComponent() {
    setActiveComponent(prevActiveComponent => {
      return activeComponent < components.length - 1
        ? prevActiveComponent + 1
        : prevActiveComponent
    });
  }

  function handlePreviousComponent() {
    setActiveComponent(prevActiveComponent => {
      return activeComponent > 0
        ? prevActiveComponent - 1
        : prevActiveComponent
    });
  }

  function getComponent(component: string) {
    switch (component) {
      case 'ndidTerms':
        return (
          <NdidTerms
            onClickNext={ndidTermsNext}
            sendTermsAcceptanceLog={sendTermsAcceptanceLog}
          />
        );
      case 'ncbTerms':
        return (
          <NcbTerms
            onClickNext={ncbTermsNext}
            sendTermsAcceptanceLog={sendTermsAcceptanceLog}
          />
        );
      case 'selectIdp':
        return (
          <SelectIdp
            onClickPrevious={selectIdpPrevious}
            onClickNext={selectIdpNext}
          />
        );
      case 'pending':
        return (
          <Pending
            onCancel={handleCancelRequest}
          />
        );
      case 'result':
        return (
          <Result />
        );
      default:
        throw new Error("Unknown step");
    }
  }

  const ndidTermsNext = React.useCallback(async () => {
    try {
      if (!ncbRequestParams) {
        sessionStorage.setItem(SessionStorage.CID, encrypt(citizenId));
        const { idp_list, on_the_fly_list } = await getIdpList(citizenId);
        if (preselectedIdp) {
          const isEnrolled = idp_list.includes(preselectedIdp);
          if (isEnrolled) {
            await createRequest(preselectedIdp);
          }
          else {
            insertComponent("selectIdp");
          }
        }
        dispatch({
          type: ActionType.SET_ENROLLED_LIST,
          payload: idp_list
        });
        dispatch({
          type: ActionType.SET_ON_THE_FLY_LIST,
          payload: on_the_fly_list
        });
      }
    }
    catch (err) {
      alert("ไม่สามารถทำรายการได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง");
    }
    handleNextComponent();
    handleNextStep();

  }, [preselectedIdp, citizenId, ncbRequestParams]);

  const ncbTermsNext = React.useCallback(async () => {
    if (preselectedIdp) {
      try {
        await createRequest(preselectedIdp);
        handleNextComponent();
        handleNextStep();
      }
      catch (err) {
        alert("ไม่สามารถทำรายการได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง");
      }
    }
    else {
      handleNextComponent();
      handleNextStep();
    }

  }, [preselectedIdp, citizenId, ncbRequestParams]);

  function selectIdpPrevious() {
    handlePreviousStep();
    handlePreviousComponent();
  }

  const selectIdpNext = React.useCallback(async () => {
    try {
      await createRequest(selectedIdp);
      handleNextComponent();
    }
    catch (err) {
      alert("ไม่สามารถทำรายการได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง");
    }
  }, [selectedIdp, citizenId, ncbRequestParams]);

  function handleCancelRequest() {
    closeRequest();
    if (redirectUrlOnCanceled) {
      window.location.replace(`${redirectUrlOnCanceled}${userId ? `?uid=${userId}` : ''}`);
    }
    else {
      handleRequestClosedOrTimedOut();
    }
  }

  React.useEffect(() => {
    if (timedOut || requestStatus || errorCode) {
      handleRequestClosedOrTimedOut();
    }
  }, [timedOut, requestStatus, errorCode]);

  function handleRequestClosedOrTimedOut() {
    sessionStorage.setItem(
      SessionStorage.REQUEST_STATUS,
      JSON.stringify({
        requestStatus,
        timedOut,
        errorCode
      })
    );
    setActiveComponent(components.length - 1);
    setActiveStep(steps.length - 1);
  }

  async function getPreservedData(ticketId: string) {
    if (!ticketId) {
      return null;
    }
    try {
      const response = await axios.get<string>(`${apiBaseUrl}/preserved_data/${ticketId}`);
      return response.data;
    }
    catch (err) {
      if (
        !axios.isAxiosError(err) ||
        !err.response ||
        err.response.status !== 404
      ) {
        throw err;
      }
      return null;
    }
  }

  async function getIdpList(citizenId: string) {
    try {
      const res = await axios.post(`${apiBaseUrl}/idp`, {
        citizen_id: citizenId,
        request_option: requestOption
      });
      return res.data;
    }
    catch (err) {
      throw err;
    }
  }

  async function createRequest(idp: string) {
    const isOnTheFly = enrolledList.find(enrolledIdp => enrolledIdp === idp) ? false : true;

    analytics.track('request_ekyc', {
      idp,
      is_on_the_fly: isOnTheFly,
    });

    const transactionRef = Math.floor(100000000 + Math.random() * 900000000).toString();
    const token = await generateRecaptchaToken();

    let filteredNcbRequestParams;

    if (ncbRequestParams) {
      const { product_code_description, ...rest } = ncbRequestParams;
      filteredNcbRequestParams = rest;
    }

    try {
      const res: {
        data: ICreateRequestResponse
      } = await axios.post(`${apiBaseUrl}/request`, {
        citizen_id: citizenId,
        node: idp,
        transaction_ref: transactionRef,
        request_option: requestOption,
        request_params: filteredNcbRequestParams,
        uid: userId,
        token
      });

      analytics.track('ekyc_requested', {
        idp,
        is_on_the_fly: isOnTheFly,
        request_id: res.data.request_id
      });

      const { request_id, reference_id, additional_data } = res.data;

      handleWebSocket({
        request_id,
        reference_id,
        idp,
        is_on_the_fly: isOnTheFly
      });

      dispatch({
        type: ActionType.SET_TRANSACTION_REF,
        payload: transactionRef
      });
      dispatch({
        type: ActionType.SET_REQUEST_ID,
        payload: request_id
      });
      dispatch({
        type: ActionType.SET_REQUEST_TIME,
        payload: Date.now()
      });

      sessionStorage.setItem(
        SessionStorage.REQUEST_STATE,
        JSON.stringify({
          transactionRef,
          idp,
          requestId: request_id,
          referenceId: reference_id,
          isOnTheFly
        })
      );
    }
    catch (err) {
      console.log(err)
      throw err;
    }
  }

  function closeRequest() {
    axios.post(`${apiBaseUrl}/close_request`, {
      request_id: requestId
    });
  }

  const sendTermsAcceptanceLog = React.useCallback((termsType: string, action: string) => {
    if (citizenId.length !== 13) {
      return;
    }

    axios.post(`${apiBaseUrl}/log/terms_acceptance`, {
      citizen_id: citizenId,
      tc_type: termsType,
      action
    });

  }, [citizenId, apiBaseUrl]);

  const handleWebSocket = React.useCallback(({
    request_id,
    reference_id,
    idp,
    is_on_the_fly
  }: {
    request_id: string;
    reference_id: string;
    idp: string;
    is_on_the_fly: boolean;
  }) => {

    if (!wsUrl) return;

    const ws = new WebSocket(wsUrl);

    ws.onopen = () => {
      ws.send(
        JSON.stringify({
          request: "SUBSCRIBE",
          channel: reference_id,
          request_id
        })
      );
    };
    ws.onmessage = (event) => {
      const { status, error } = JSON.parse(JSON.parse(event.data).message);
      if (typeof status === "object") {
        if (status.success === false) {
          alert("ไม่สามารถทำรายการได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง");
        }
      } else {
        if (error) {
          dispatch({
            type: ActionType.SET_ERROR_CODE,
            payload: error
          });
        }

        dispatch({
          type: ActionType.SET_REQUEST_STATUS,
          payload: status
        });

        analytics.track('ekyc_result', {
          status,
          idp,
          is_on_the_fly,
          request_id
        });
      }
    };

    ws.onerror = () => {
      console.error("websocket error");
      ws.close();
    };

  }, []);

  React.useEffect(() => {
    const storedRequestState = sessionStorage.getItem(SessionStorage.REQUEST_STATE);
    if (storedRequestState) {
      const {
        transactionRef,
        idp,
        requestId,
        referenceId,
        isOnTheFly
      } = JSON.parse(storedRequestState);
      dispatch({
        type: ActionType.SET_TRANSACTION_REF,
        payload: transactionRef
      });
      dispatch({
        type: ActionType.SET_SELECTED_IDP,
        payload: idp
      });
      dispatch({
        type: ActionType.SET_REQUEST_ID,
        payload: requestId
      });
      handleWebSocket({
        request_id: requestId,
        reference_id: referenceId,
        idp,
        is_on_the_fly: isOnTheFly
      });
    }
  }, [handleWebSocket])

  function insertComponent(componentToInsert: string) {
    interface IComponentSequence {
      ndidTerms: number;
      ncbTerms: number;
      selectIdp: number;
      pending: number;
      result: number
    }

    const componentSequence: IComponentSequence = {
      ndidTerms: 1,
      ncbTerms: 2,
      selectIdp: 3,
      pending: 4,
      result: 5
    };

    setComponents((prevComponents) => {
      const indexToInsert: number = prevComponents.findIndex(component => {
        const componentToInsertPriorNum: number = componentSequence[componentToInsert as keyof IComponentSequence]
        const currentComponentPriorNum: number = componentSequence[component as keyof IComponentSequence];
        return componentToInsertPriorNum < currentComponentPriorNum;
      });

      if (!prevComponents.includes(componentToInsert)) {
        const modifiedComponents = [...prevComponents];
        modifiedComponents.splice(
          indexToInsert,
          0,
          componentToInsert
        );
        return modifiedComponents;
      }
      return prevComponents;
    });
  }

  function insertStep(stepToInsert: string) {
    interface IStepSequence {
      ndidTerms: number;
      ncbTerms: number;
      authen: number;
      result: number;
    };
    const stepSequence: IStepSequence = {
      ndidTerms: 1,
      ncbTerms: 2,
      authen: 3,
      result: 4
    };

    setSteps(prevSteps => {
      const indexToInsert: number = prevSteps.findIndex(step => {
        const stepToInsertPriorNum: number = stepSequence[stepToInsert as keyof IStepSequence];
        const currentStepPriorNum: number = stepSequence[step as keyof IStepSequence];
        return stepToInsertPriorNum < currentStepPriorNum;
      });

      if (!prevSteps.includes(stepToInsert)) {
        const modifiedSteps = [...prevSteps];
        modifiedSteps.splice(
          indexToInsert,
          0,
          stepToInsert
        );
        return modifiedSteps
      }
      return prevSteps;
    });
  }

  const theme = createTheme({
    typography: {
      fontFamily: "'Kanit',  sans-serif"
    },
  });

  return isInitialized
    ? (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Navbar />
        <Box>
          <Stepper
            steps={steps}
            activeStep={activeStep}
          />
          <Paper
            variant="outlined"
            sx={{ mt: { xs: 2, md: 2 }, mb: { xs: 0, md: 2 }, mx: { xs: 0, sm: 0, md: 2 }, py: { xs: 2, md: 3 }, px: { xs: 0, md: 3 } }}
          >
            {getComponent(components[activeComponent])}
          </Paper>
        </Box>
      </ThemeProvider>
    )
    : <Loading />
}