import React from "react";
import { Box } from "@mui/material";
import Context, { ActionType } from "../../context";
import Success from "./components/Success";
import Error from "./components/Error";

export default function Result() {
  const { state } = React.useContext(Context);
  const { requestStatus } = state;

  return (
    <Box sx={{ my: 5, textAlign: "center" }}>
      {requestStatus === "completed"
        ? (<Success />)
        : (<Error />)
      }
    </Box>
  );
}
