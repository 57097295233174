import React from 'react';
import { Typography, Grid, Box, Paper } from '@mui/material';
import Tutotial from './Tutorial';
import Context from '../../../context';

import tutorial1 from '../../../assets/tutorial/tutorial1.png'
import tutorial2 from '../../../assets/tutorial/tutorial2.png'
import tutorial3 from '../../../assets/tutorial/tutorial3.png'
import tutorial4 from '../../../assets/tutorial/tutorial4.png'

const tutorials = [
  {
    id: "tutorial1",
    image: tutorial1,
    text: "เป็นลูกค้าของผู้ให้บริการ (มีบัญชีเงินฝาก/หมายเลขโทรศัพท์)",
    alt: "Bank Account"
  },
  {
    id: "tutorial2",
    image: tutorial2,
    text: "เคยเสียบบัตรประชาชนผ่านเครื่องอ่านบัตรของผู้ให้บริการ",
    alt: "Citizen Card"
  },
  {
    id: "tutorial3",
    image: tutorial3,
    text: "มีแอปพลิเคชันของผู้ให้บริการและพร้อมใช้งาน",
    alt: "Mobile App"
  },
  {
    id: "tutorial4",
    image: tutorial4,
    text: "เคยถ่ายรูปเปรียบเทียบใบหน้าที่แอปพลิเคชันหรือช่องทางอื่นของผู้ให้บริการ",
    alt: "Face Recognition"
  }
]

export default function TutorialSection() {
  const { state } = React.useContext(Context);
  const { selectedIdp } = state;

    return selectedIdp
      ? (
        <React.Fragment>
        <Box sx={{mt: 3}}>
            <Typography
                fontSize={{
                    xs: 18
                }}
                fontWeight="medium"
                sx={{
                    textAlign: "center",
                    padding: 1
                }}
            >
                คุณสมบัติเพื่อยืนยันตัวตนผ่าน NDID
            </Typography>
        </Box>
        <Paper
            variant="outlined"
            sx={{
                padding: 2
            }}
        >
            <Grid
                container
                alignItems="center"
                spacing={2}
            >
                {tutorials.map(tutorial => (
                    <Grid
                        item
                        key={tutorial.id}
                        xs={12}
                        sm={6}
                        md={6}
                        lg={3}
                    >
                        <Tutotial
                            image={tutorial.image}
                            text={tutorial.text}
                            alt={tutorial.alt}
                        />
                    </Grid>
                ))}
            </Grid>
        </Paper>
    </React.Fragment>
    )
    : null
}