import React from "react";
import { Typography, Box } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Context from "../../../context";
import Button from "../../Common/Button";
import { redirectUrlOnCompleted, resultPageObjectiveMessage } from "../../../config";

export default function Success() {
  const { state } = React.useContext(Context);
  const { userId } = state;

  React.useEffect(() => {
    if (redirectUrlOnCompleted) {
      setTimeout(() => {
        window.location.replace(`${redirectUrlOnCompleted}${userId ? `?uid=${userId}` : ''}`);
      }, 15000);
    }
  }, []);

  return (
    <React.Fragment>
      <CheckCircleIcon fontSize="large" color="success"></CheckCircleIcon>
      <Typography variant="h6" sx={{ padding: 3 }}>
        ท่านได้ทำการยืนยันตัวตนเรียบร้อยแล้ว
      </Typography>
      {
        resultPageObjectiveMessage &&
        <Typography>
          {resultPageObjectiveMessage}
        </Typography>
      }
      <Box sx={{ margin: 5 }}>
          <Button
            buttonText="ถัดไป"
            colorType="confirm"
            onClick={() => window.location.replace(`${redirectUrlOnCompleted}${userId ? `?uid=${userId}` : ''}`)}
          />
      </Box>
    </React.Fragment>
  )
}