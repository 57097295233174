import crypto from "crypto-js";
import { ActionType } from "./context";
import { SessionStorage } from "./App";
import nodes from "./nodes";

export const idpIdList = nodes.map(node => node.id);
const k = "qMSPDD3iFb9NPwkmVItT5g5pWBGRhqF0y8n4zLhxJAcdKzfGAGRpFcmhWEfEfU76";

export function restoreState(dispatch: React.Dispatch<any>) {
  const storedIdpList = sessionStorage.getItem(SessionStorage.IDP_LIST);
  const storedPreselectedIdp = sessionStorage.getItem(SessionStorage.PRESELECTED_IDP);
  const storedUid = sessionStorage.getItem(SessionStorage.UID);
  const storedRequestOption = sessionStorage.getItem(SessionStorage.REQUEST_OPTION);
  const storedRequestStatus = sessionStorage.getItem(SessionStorage.REQUEST_STATUS);
  const storedRequestTime = sessionStorage.getItem(SessionStorage.REQUEST_TIME);

  if (storedIdpList) {
    const { enrolledList, onTheFlyList } = JSON.parse(storedIdpList);
    dispatch({
      type: ActionType.SET_ENROLLED_LIST,
      payload: enrolledList
    });
    dispatch({
      type: ActionType.SET_ON_THE_FLY_LIST,
      payload: onTheFlyList
    });
  }
  if (storedPreselectedIdp) {
    dispatch({
      type: ActionType.SET_PRESELECTED_IDP,
      payload: storedPreselectedIdp
    });
  }
  if (storedUid) {
    dispatch({
      type: ActionType.SET_USER_ID,
      payload: storedUid
    });
  }
  if (storedRequestOption) {
    dispatch({
      type: ActionType.SET_REQUEST_OPTION,
      payload: Number(storedRequestOption)
    });
  }
  if (storedRequestStatus) {
    const { requestStatus, timedOut, errorCode } = JSON.parse(storedRequestStatus);
    if (requestStatus) {
      dispatch({
        type: ActionType.SET_REQUEST_STATUS,
        payload: requestStatus
      });
    }
    if (timedOut) {
      dispatch({
        type: ActionType.SET_TIMED_OUT,
        payload: timedOut === "true" ? true : false
      });
    }
    if (errorCode) {
      dispatch({
        type: ActionType.SET_ERROR_CODE,
        payload: Number(errorCode)
      });
    }
  }
  if (storedRequestTime) {
    dispatch({
      type: ActionType.SET_REQUEST_TIME,
      payload: Number(storedRequestTime)
    })
  }
}

export function getIdpName(idpId: string): string | undefined {
  const idp:
    | {
      name: string;
      id: string;
      img: string;
    }
    | undefined = nodes.find((node) => node.id === idpId);
  return idp?.name;
}

export function encrypt(dataToEncrypt: any) {
  return crypto.AES.encrypt(typeof dataToEncrypt === "string" ? dataToEncrypt : JSON.stringify(dataToEncrypt), k).toString();
}

export function decrypt(dataToDecrypt: any) {
  return crypto.AES.decrypt(dataToDecrypt, k).toString(crypto.enc.Utf8);
}