import React from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import IdpCard from './IdpCard'

interface INodeDetail {
    name: string;
    img: string;
    id: string;
}

export default function IdpTable({
    tableHeader,
    idpList
}: {
    tableHeader: string;
    idpList: INodeDetail[]
}) {

    return idpList.length
        ? (
            <React.Fragment>
                <Box sx={{mt: 3}}>
                    <Typography
                        fontSize={{
                            xs: 18
                        }}
                        fontWeight="medium"
                        sx={{
                            textAlign: "center",
                            padding: 1
                        }}
                    >
                        {tableHeader}
                    </Typography>
                </Box>
                <Paper
                    variant="outlined"
                    sx={{
                        padding: 2
                    }}
                >
                    <Grid
                        container
                        alignItems="center"
                        spacing={2}
                    >
                        {idpList.map(idp => (
                            <Grid
                                item
                                key={idp.id}
                                xs={12}
                                sm={4}
                                md={4}
                                lg={3}
                            >
                                <IdpCard
                                    logo={idp.img}
                                    id={idp.id}
                                    name={idp.name}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Paper>
            </React.Fragment>
        )
        : null;
}