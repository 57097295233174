import React from "react";
import { AppBar, Toolbar, Typography, Box } from "@mui/material"
import { marketingNameEn, marketingNameTh } from "../../config";

export default function Navbar () {
    return (
        <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: "relative",
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar
          sx={{
            p: 1,
            backgroundColor: "white",
          }}
        >
          <img
            src='config/logo.png'
            alt=""
            style={{
              maxWidth: "60px",
              maxHeight: "60px",
            }}
          />
          <Box
            sx={{
              textAlign: "right",
              marginLeft: "auto"
            }}
          >
            <Typography
              variant="body1"
              fontSize={{
                xs: 12.5,
                sm: 15
            }}
              sx={{
                fontWeight: "medium"
              }}
            >
              {marketingNameTh}
            </Typography>
            <Typography
              variant="body1"
              fontSize={{
                xs: 12.5,
                sm: 15
            }}
              sx={{
                fontWeight: "medium"
              }}
            >
              {marketingNameEn}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
    )
}