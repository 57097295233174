import Analytics from "analytics";
import * as session from "@analytics/session-utils"

import EFormAnalyticPlugin from "./plugin";
import { analyticApiKey, analyticApiUrl } from "../config"

const analytics = Analytics({
  app: 'eform',
  version: '1.0',
  plugins: [
    EFormAnalyticPlugin({
      apiKey: analyticApiKey,
      host: analyticApiUrl,
    }),
  ]
});

session.getTabSession();
analytics.identify(session.getSession().id, {
  tabSessionId: session.getTabSession().id,
  pageSessionId: session.getPageSession().id,
});

export default analytics;