import React from "react";
import { Button } from "@mui/material";
import { confirmButtonColor, cancelButtonColor } from "../../config";

interface IButtonProps extends React.HTMLProps<HTMLButtonElement> {
    href?: string;
    disabled?: boolean;
    colorType?: string;
    buttonText: string;
    onClick?: () => void;
}

const ButtonComponent = ({
    href,
    disabled = false,
    colorType,
    buttonText,
    onClick
}: IButtonProps) => {

    const buttonSx = {
        "&.MuiButton-root.MuiButton-contained": {
            ":enabled": {
                backgroundColor: colorType === "cancel"
                    ? cancelButtonColor
                    : confirmButtonColor
            }
        }
    }

    return (
        <Button
            variant='contained'
            href={href}
            disabled={disabled}
            onClick={onClick}
            sx={buttonSx}
        >
            {buttonText}
        </Button>
    )
}

export default ButtonComponent;