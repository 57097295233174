import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { Typography, Table, TableContainer, TableCell, TableRow, TableHead, TableBody, Paper, Button, Chip, Dialog, Slide, DialogTitle, Box, DialogContent, Stack, IconButton, useMediaQuery, DialogActions, AppBar, Toolbar } from "@mui/material";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CallIcon from '@mui/icons-material/Call';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from "@mui/material/transitions";

import Context from "../../../context";
import { getIdpName } from "../../../utils";
import idpList from '../../../nodes';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Error() {
    const isXs = useMediaQuery('(max-width:600px)');

    const { state } = React.useContext(Context);
    const { requestStatus, timedOut, errorCode, selectedIdp, preselectedIdp, enrolledList, onTheFlyList } = state;

    const [showInfo, setShowInfo] = React.useState<boolean>(false);

    const infoList = React.useMemo(() => {
        return idpList.filter(idp => enrolledList.includes(idp.id) || onTheFlyList.includes(idp.id));
    }, [enrolledList, onTheFlyList]);

    function getErrorMessage() {
        if (requestStatus === 'rejected') {
            return "ท่านได้ปฏิเสธการยืนยันตัวตนของผู้ให้บริการยืนยันตัวตนที่ท่านเลือก กรุณาทำรายการใหม่หรือเลือกผู้ให้บริการยืนยันตัวตนรายอื่น";
        }
        if (timedOut) {
            return "ท่านไม่สามารถทํารายการได้ในขณะนี้ กรุณาทํารายการใหม่หรือเลือกผู้ให้บริการยืนยันตัวตนรายอื่น หรือกลับไปที่หน้าหลักเพื่อเลือกวิธีการเข้าสู่ระบบใหม่อีกครั้ง";
        }
        switch (errorCode) {
            case 30000:
                return "ผู้ให้บริการยืนยันตัวตนที่ท่านเลือกไม่สามารถให้บริการได้ในขณะนี้ กรุณาทำรายการใหม่หรือเลือกผู้ให้บริการยืนยันตัวตนรายอื่น";
            case 30200:
                return "ไม่สามารถทำรายการได้ในขณะนี้ กรุณาตรวจสอบเลขบัตรประชาชนของท่านและทำรายการใหม่ หรือเลือกผู้ให้บริการยืนยันตัวตนรายอื่น";
            case 30300:
                return "ไม่พบข้อมูลของท่านในฐานข้อมูลผู้ใช้บริการของผู้ให้บริการยืนยันตัวตนที่ท่านเลือก กรุณาเลือกผู้ให้บริการยืนยันตัวตนรายอื่นที่ท่านเคยลงทะเบียนและมีโมบายแอปพลิเคชัน";
            case 30400:
                return "ขออภัย ข้อมูลของท่านไม่เป็นปัจจุบัน กรุณาติดต่อผู้ให้บริการยืนยันตัวตนที่ท่านเลือกเพื่ออัปเดทข้อมูล หรือเลือกผู้ให้บริการยืนยันตัวตนรายอื่น";
            case 30500:
                return "การยืนยันตัวตนไม่สำเร็จเนื่องจากท่านระบุ PIN ผิด, ภาพถ่ายไม่ชัดเจน กรุณาทำรายการใหม่ หรือเลือกผู้ให้บริการยืนยันตัวตนรายอื่น";
            case 30510:
                return "การยืนยันตัวตนไม่สำเร็จเนื่องจากท่านระบุ PIN/Password ไม่ถูกต้อง กรุณาทำรายการใหม่ หรือเลือกผู้ให้บริการยืนยันตัวตนรายอื่น";
            case 30520:
                return "การยืนยันตัวตนไม่สำเร็จเนื่องจากท่านยืนยันตัวตนด้วยระบบจดจำใบหน้า (Face Recognition) ไม่ผ่านตามเงื่อนไขที่กำหนด กรุณาทำรายการใหม่ หรือเลือกผู้ให้บริการยืนยันตัวตนรายอื่น";
            case 30530:
                return "การยืนยันตัวตนไม่สำเร็จเนื่องจากท่านระบุรหัส OTP ไม่ถูกต้อง กรุณาทำรายการใหม่ หรือเลือกผู้ให้บริการยืนยันตัวตนรายอื่น";
            case 30600:
                return "การยืนยันตัวตนไม่สำเร็จ เนื่องจากได้ท่านยกเลิกรายการยืนยันตัวตนที่โมบายแอปพลิเคชันของผู้ให้บริการยืนยันตัวตน กรุณาเลือกผู้ให้บริการยืนยันตัวตนรายอื่น";
            case 30610:
                return "การยืนยันตัวตนไม่สำเร็จ เนื่องจากท่านไม่ได้ยอมรับเงื่อนไขการให้บริการยืนยันตัวตนของผู้ให้บริการยืนยันตัวตนที่ท่านเลือก กรุณาทำรายการใหม่ หรือเลือกผู้ให้บริการยืนยันตัวตนรายอื่น";
            case 30700:
                return "การยืนยันตัวตนไม่สำเร็จ เนื่องจากผู้ให้บริการยืนยันตัวตนที่ท่านเลือกไม่สามารถให้บริการได้ในขณะนี้ กรุณาทำรายการใหม่ หรือเลือกผู้ให้บริการยืนยันตัวตนรายอื่น";
            case 30800:
                return "ท่านยังไม่ได้ลงทะเบียนและยอมรับเงื่อนไขการใช้บริการ NDID ที่โมบายแอปพลิเคชันของผู้ให้บริการยืนยันตัวตน กรุณาดำเนินการก่อนทำรายการ";
            case 30900:
                return `ไม่สามารถทำรายการได้ในขณะนี้ เนื่องจากอยู่นอกเวลาการให้บริการของ ${selectedIdp ? getIdpName(selectedIdp) : getIdpName(preselectedIdp)} กรุณาเลือกผู้ให้บริการยืนยันตัวตนรายอื่น`;
            case 31000:
                return "พบข้อผิดพลาดในเอกสาร/สัญญาที่ท่านกำลังจะลงนาม";
            case 40000:
                return "ขออภัย ท่านไม่สามารถทำรายการได้ในขณะนี้ กรุณาทำรายการใหม่";
            case 40200:
                return "ขออภัย ไม่สามารถดำเนินการต่อได้เนื่องจากท่านใส่เลขบัตรประชาชนไม่ถูกต้อง กรุณาทำรายการใหม่อีกครั้ง";
            case 40300:
                return "ขออภัย ไม่สามารถดำเนินการต่อได้";
            case 40400:
                return "ขออภัย ไม่สามารถดำเนินการต่อได้เนื่องจากข้อมูลไม่ถูกต้อง กรุณาทำรายการใหม่อีกครั้ง";
            case 40500:
                return "ขออภัย ไม่สามารถดำเนินการต่อได้";
            default:
                return "ขออภัย ไม่สามารถดำเนินการต่อได้เนื่องจากข้อมูลไม่ถูกต้อง กรุณาทำรายการใหม่อีกครั้ง หรือติดต่อผู้ให้บริการยืนยันตัวตนที่ท่านเลือก";
        }

    }

    return (
        <React.Fragment>
            <CancelIcon fontSize="large" color="error"></CancelIcon>
            <Typography sx={{ padding: 2 }}>{getErrorMessage()}</Typography>
            <Button
                variant="outlined"
                sx={{ mt: 5 }}
                startIcon={<SearchOutlinedIcon />}
                onClick={() => setShowInfo(true)}
            >
                ข้อมูลผู้ให้บริการยืนยันตัวตน</Button>
            <Dialog
                open={showInfo}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setShowInfo(false)}
                fullScreen={isXs}
            >
                <TableContainer
                    sx={{
                        alignItems: "center",
                        position: "relative",
                        padding: {
                            sm: 3
                        }
                    }}
                >
                    {isXs
                        ? (
                            <AppBar sx={{ position: "sticky" }}>
                                <Toolbar>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={() => setShowInfo(false)}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography sx={{ ml: 2 }} variant="h6" component="div">
                                        ข้อมูลผู้ให้บริการยืนยันตัวตน
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                        )
                        : (
                            <IconButton
                                sx={{
                                    position: "absolute",
                                    right: 0, top: 0
                                }}
                                onClick={() => setShowInfo(false)}
                            >
                                <CloseIcon />
                            </IconButton>
                        )
                    }
                    
                    <Table >
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">ผู้ให้บริการ</TableCell>
                                <TableCell align="center">ช่วงเวลา</TableCell>
                                <TableCell align="center">ช่องทาง</TableCell>
                                <TableCell align="center">Callcenter</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {infoList.map((idp) => {
                                if (!idp.contactInfo) return;
                                return (
                                    <TableRow key={idp.id}>
                                        <TableCell align="left">{idp.name}</TableCell>
                                        <TableCell align="center">{idp.contactInfo.businessHours}</TableCell>
                                        <TableCell align="center">
                                            <Box
                                                component="img"
                                                src={idp.img}
                                                alt={idp.appName}
                                                sx={{
                                                    borderRadius: 2.5,
                                                    maxWidth: {
                                                        xs: 40,
                                                        sm: 50,
                                                    }
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Stack spacing={1} direction="column">
                                                {idp.contactInfo.phoneNumbers.map(call => (
                                                    <Chip
                                                        label={call}
                                                        variant="outlined"
                                                        color="success"
                                                        clickable
                                                        icon={<CallIcon fontSize="small" sx={{ pl: 0.5 }} />}
                                                        sx={{ mx: "auto" }}
                                                        component="a"
                                                        href={`tel:${call}`}
                                                    />
                                                ))}
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Dialog>
        </React.Fragment>
    )
}